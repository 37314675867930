<template>
  <!--begin::Purchase Order Listing-->
  <div class="purchase-order-template purchase-order-listing">
    <v-container
      fluid
      class="visit-listing white-background main-listing-page pb-0"
    >
      <v-tabs
        active-class="custom-tab-active"
        v-model="purchaseTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent tab-sticky"
      >
        <v-tab
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#newRequest"
        >
          <template v-if="counts.new > 0">
            <v-badge bordered color="orange darken-4" :content="counts.new">
              New Requests
            </v-badge>
          </template>
          <template v-else>New Requests</template>
        </v-tab>
        <v-tab
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#raised"
        >
          <template v-if="counts.raised > 0">
            <v-badge bordered color="orange darken-4" :content="counts.raised">
              Raised PO
            </v-badge>
          </template>
          <template v-else>Raised PO</template>
        </v-tab>
        <v-tab
          :disabled="pageLoading"
          class="font-size-16 font-weight-600 px-8"
          href="#invoiceGenerated"
        >
          <template v-if="counts.generated > 0">
            <v-badge
              bordered
              color="orange darken-4"
              :content="counts.generated"
            >
              Invoice Generated
            </v-badge>
          </template>
          <template v-else>Invoice Generated</template>
        </v-tab>
        <v-tabs-items v-model="purchaseTab">
          <v-tab-item value="newRequest">
            <PurchaseOrderListingTemplate
              :is-admin="isAdmin"
              purchaseType="new"
            ></PurchaseOrderListingTemplate>
          </v-tab-item>
          <v-tab-item value="raised">
            <PurchaseOrderListingTemplate
              :is-admin="isAdmin"
              purchaseType="raised"
            ></PurchaseOrderListingTemplate>
          </v-tab-item>
          <v-tab-item value="invoiceGenerated">
            <PurchaseOrderInvoiceListingTemplate
              :is-admin="isAdmin"
              purchaseType="invoiceGenerated"
            ></PurchaseOrderInvoiceListingTemplate>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-container>
  </div>
  <!--end::Purchase Order Listing-->
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH } from "@/core/services/store/request.module";
import PurchaseOrderListingTemplate from "@/view/pages/purchase-order/Purchase-Order-Listing-Template";
import PurchaseOrderInvoiceListingTemplate from "@/view/pages/purchase-order/Purchase-order-invoice-listing";

export default {
  mixins: [CommonMixin],
  name: "purchase-order-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageLoading: false,
      pageModule: "purchase-order-listing",
      currentSortClass: null,
      purchaseTab: "",
      status: "new",
      isAdmin: false,
      statusList: [],
      counts: {
        new: 0,
        raised: 0,
        genrated: 0,
        paid: 0,
        delivered: 0,
      },
      moreActions: [
        /*{
          title: "Export Purchase Order(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        {
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Approved",
          action: "mark_as_approved",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Closed",
          action: "mark_as_closed",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Cancelled",
          action: "mark_as_cancelled",
          icon: "mdi-check-all",
        },
        /*{ title: "Delete", action: "delete", icon: "mdi-delete" },
        { title: "Download PDF", action: "download_pdf", icon: "mdi-file-pdf" },
        { title: "Print", action: "print", icon: "mdi-printer" }*/
      ],
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      },
    },
  },
  components: {
    PurchaseOrderListingTemplate,
    PurchaseOrderInvoiceListingTemplate,
  },
  methods: {
    bulkAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_approved":
          _this.updatePurchaseOrder(2);
          break;
        case "mark_as_closed":
          _this.updatePurchaseOrder(3);
          break;
        case "mark_as_cancelled":
          _this.updatePurchaseOrder(4);
          break;
      }
    },
    updatePurchaseOrder(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "purchase-order/status",
          data: { purchase_order: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.bulkPurchaseOrderExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("purchase-order");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Purchase Order",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: true,
        checkbox: true,
        order: 0,
        width: "50px",
      },
      {
        headerName: "Purchase Order #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Basic Details",
        field: "basic_details",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Supplier",
        field: "supplier",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "300px",
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "200px",
      },
      {
        headerName: "Amount",
        field: "amount",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px",
      },
    ];

    _this.defaultColShow = _this.defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    /*  _this.applyColState(); */

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
